<template>
  <div class="row">
    <div class="col-md-6">
      <b-spinner v-if="!ready" variant="info"></b-spinner>
      <b-card :header="group.name" v-if="ready" no-body>
        <b-list-group flush>
          <b-list-group-item>
            Start:
            <span class="text-info">
              {{ group.startdate }}
            </span>
          </b-list-group-item>
          <b-list-group-item>
            Eind:
            <span class="text-info">
              {{ group.enddate }}
            </span>
          </b-list-group-item>
          <b-list-group-item v-if="group.profiles.length">
            Profiel:
            <span class="text-info">
              <b-badge variant="info" v-for="profile in group.profiles" :key="profile.id" class="mr-1">{{ profile.name }}</b-badge>
            </span>
          </b-list-group-item>
          <b-list-group-item v-if="group.status">
            Status:
            <span class="text-info">
              {{ group.status.name }}
            </span>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card v-if="ready && group.locations.length" class="mt-3" no-body>
        <b-card-header>
          Locaties
          <b-badge variant="info" pill>{{ group.locations.length }}</b-badge>
        </b-card-header>
        <b-list-group flush>
          <b-list-group-item v-for="location in group.locations" :key="location.id">
            {{ location.name }}
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </div>
    <div class="col-md-6">
      <b-card header="Events" v-if="ready" no-body>
        <b-list-group flush v-if="events.length">
          <b-list-group-item v-for="event in events" :key="event.id">
            <router-link :to="{name:'Event', params: { id: event.id }}">{{ event.name }}</router-link>
            <div class="text-muted small">
              <b-badge variant="info" v-if="event.parentgroup" class="mr-1">{{event.parentgroup.name}}</b-badge>
              {{formatDate(event.starttime)}} - {{formatDate(event.endtime)}}
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      group: null,
      events: [],
      ready: false,
      error: false
    }
  },
  methods: {
    ...mapActions(['apiGet', 'apiGetPaginate']),

    formatDate(date) {
      const dt = new Date(date)
      const minutes = (dt.getMinutes() < 10) ? "0"+ dt.getMinutes() : dt.getMinutes()
      const hours = (dt.getHours() < 10) ? "0"+ dt.getHours() : dt.getHours()
      return dt.getDate() +"-"+ (dt.getMonth() + 1) +"-"+ dt.getFullYear() +" "+ hours +":"+ minutes
    },

    getEvents(group) {
      this.apiGetPaginate("events/groupid:"+ group.id).then((result) => {
        this.events = result
      })
    }
  },
  mounted() {
    const groupId = this.$route.params.id
    this.apiGet("group/"+ groupId).then((result) => {
      this.group = result.data
      this.ready = true
      this.getEvents(this.group)
    }).catch(() => {
      this.error = true
      this.ready = true
    })
  }
}
</script>